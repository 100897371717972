export const auth = {
  signin(username, password, cb, errorCb) {
    const formdata = new FormData();
    formdata.append('username', username);
    formdata.append('password', password);

    fetch(process.env.REACT_APP_KEY_SERVER_URL + '/login', {
      method: 'POST',
      body: formdata
    })
      .then(response => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then(
        result => {
          if (result.status === 'inactive') {
            errorCb({ message: 'Inactive user' });
          } else {
            cb(result);
          }
        },
        error => {
          errorCb({ message: 'Invalid credentials' });
        }
      )
      .catch(error => {
        errorCb({ message: 'Invalid credentials' });
      });
  },
  signout(cb) {
    setTimeout(cb, 100);
  }
};
