import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../hooks/auth';
import Navbar from './Navbar';

const PrivateRoute = ({ component: Component, ...rest }) => {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={props =>
        auth.isLoggedIn() ? (
          <main>
            <aside className="bg-white fullWidth">
              <Navbar />
              <Component {...props} />
            </aside>
          </main>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};
export default PrivateRoute;
