const Modal = ({ title, message, handleClose }) => {
  return (
    <div className="modal" tabIndex="-1" role="dialog" style={{ paddingRight: '1rem', paddingTop: '5rem', display: 'block' }}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-error">{title}</h5>
          </div>
          <div className="modal-body">
            <p>{message}</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={handleClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Modal;
