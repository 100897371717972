import framesLogo from '../../images/frames-logo.png';
import multiUser from '../../images/multiuser.svg';
import androidIosSystem from '../../images/android-ios-system.svg';
import pcOrVrTechnologies from '../../images/pc-or-vr-technologies.svg';
import liveStreming from '../../images/livestreming.svg';
import webxLogo from '../../images/webx-logo.png';
import { Link } from 'react-router-dom';

const WelcomePage = () => {
  return (
    <>
      <div className="sidebar">
        <div className="spacer-xs-6"></div>
        <header>
          <div className="logo">
            <img src={framesLogo} alt="" />
          </div>
        </header>
        <section className="main-content overflow-y-auto custom-scroll ">
          <div className="spacer-xs-4"></div>
          <div className="row">
            <div className="col-sm-12 text-center">
              <h1 className="entry-title">
                <span>Your new way to collaborate.</span>
                <br />
                Create your metaverse
              </h1>
            </div>
          </div>
          <div>
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <div className="entry-info">
                  <ul className="list-unstyled p-0 mb-0">
                    <li className="fw-normal">
                      <img src={multiUser} alt="" /> MultiUser
                    </li>
                    <li className="fw-normal">
                      <img src={androidIosSystem} alt="" /> Android & IOS System
                    </li>
                    <li className="fw-normal">
                      <img src={pcOrVrTechnologies} alt="" /> Pc or VR Technologies
                    </li>
                    <li className="fw-normal">
                      <img src={liveStreming} alt="" /> Live Streaming
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="spacer-xs-10"></div>
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <div className="d-grid gap-3">
                  <span
                    className="btn btn-primary"
                    onClick={() => {
                      const url = `${process.env.REACT_APP_VECTION_URL}/learn-more`;
                      window.open(url, '_blank');
                    }}
                  >
                    Learn More
                  </span>
                  <Link to="/login" className="btn btn-secondary">
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className="panel-footer">
          <p>Powered By</p>
          <div className="footer-logo">
            <img src={webxLogo} alt="" />
          </div>
        </footer>
        <div className="spacer-xs-3"></div>
      </div>
      <div className="background background01"></div>
    </>
  );
};

export default WelcomePage;
