import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRegister } from '../../hooks/register';
import { Loader } from '../Loader';

export default function ConfirmEmailPage() {
  let history = useHistory();
  let register = useRegister();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const validation_token = query.get('validation_token');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      verifyEmail();
    }
  });
  const verifyEmail = () => {
    setIsLoading(true);
    register.verifyEmail(
      validation_token,
      data => {
        history.replace({ pathname: '/login' });
      },
      error => {
        history.replace({ pathname: '/signup' });
      }
    );
  };

  return <Loader />;
}
