import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';

export default function LogoutPage() {
  let auth = useAuth();
  let history = useHistory();

  useEffect(() => {
    auth && auth.signout(() => history.push('/login'));
  });

  return <p>Logging out...</p>;
}
