export const scenes = {
  scenesList(cb, errorCb) {
    const client_id = localStorage.getItem('client_id');
    const token = localStorage.getItem('token');
    fetch(process.env.REACT_APP_FRAMES_SERVER_URL + '/services/loadScene.php?client_id=' + client_id + '&token=' + token, {
      method: 'GET'
    })
      .then(res => res.json())
      .then(
        result => {
          cb(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        error => {
          errorCb(error);
        }
      );
  },
  generateTempKey(sceneName, cb, errorCb) {
    const formdata = new FormData();
    const client_id = localStorage.getItem('client_id');
    const token = localStorage.getItem('token');
    formdata.append('scenename', sceneName);
    formdata.append('clientid', client_id);
    formdata.append('token', token);

    fetch(process.env.REACT_APP_KEY_SERVER_URL + '/temp-key', {
      method: 'POST',
      body: formdata
    })
      .then(response => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then(
        result => {
          if (result.status === 200) {
            cb(result);
          } else {
            errorCb(result);
          }
        },
        error => {
          errorCb(error);
        }
      )
      .catch(error => {
        errorCb(error);
      });
  },
  autoLogin(machine, tempkey, cb, errorCb) {
    const formdata = new FormData();
    formdata.append('key', tempkey);
    formdata.append('machine', machine);
    fetch(process.env.REACT_APP_KEY_SERVER_URL + '/auto-login', {
      method: 'POST',
      body: formdata
    })
      .then(response => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then(
        result => {
          if (result.status === 200) {
            cb(result);
          } else {
            errorCb(result);
          }
        },
        error => {
          errorCb(error);
        }
      )
      .catch(error => {
        errorCb(error);
      });
  }
};
