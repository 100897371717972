import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../hooks/auth';

const PublicRoute = ({ component: Component, hasWhiteBackground, restricted, ...rest }) => {
  // restricted routes like Login, signUp for authorised users
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={props =>
        auth.isLoggedIn() && restricted ? (
          <Redirect to="/dashboard" />
        ) : (
          <main>
            <aside className={hasWhiteBackground ? 'bg-white fullWidth' : ''}>
              <Component {...props} />
            </aside>
          </main>
        )
      }
    />
  );
};

export default PublicRoute;
