import { useEffect, useState } from 'react';
import { Loader } from '../Loader';

const app = new window.Webex.Application();

export default function DownloadPage() {
  const [isAppLoaded, setIsAppLoaded] = useState(false);
  const [appReadyError, setAppReadyError] = useState(false);
  const [appReadyErrorMsg, setAppReadyErrorMsg] = useState(null);

  let url = 'frames://app';

  const downloadApp = osType => {
    var link = document.createElement('a');
    link.target = '_self';
    link.href = osType === 'mac' ? process.env.REACT_APP_FRAMES_FOR_MAC : process.env.REACT_APP_FRAMES_FOR_WINDOWS;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleAppReady = () => {
    app
      .onReady()
      .then(() => {
        setIsAppLoaded(true);
      })
      .catch(error => {
        console.log('Error: ', error);
        setAppReadyError(true);
        setAppReadyErrorMsg('onReadyError');
      });
  };

  useEffect(() => {
    handleAppReady();
  });

  return isAppLoaded ? (
    <>
      <div className="background-participant">
        <h2>
          This is the last step. <span>Are you ready?</span>
        </h2>
      </div>
      <section className="main-content participant-content overflow-y-auto">
        <div className="spacer-xs-4"></div>
        <div className="row justify-content-center">
          <div className="col-sm-11 text-center">
            <h4 className="click-the-bottom">
              <span>Click the button</span> to proceed with frame s
            </h4>

            <div className="d-grid gap-3">
              <a href={url} className="btn btn-secondary">
                Launch the software
              </a>
            </div>
          </div>
          <div className="col-sm-11"></div>
          <div className="spacer-xs-3"></div>
        </div>
        <div className="spacer-xs-2"></div>
        <h3 className="entry-title text-black text-center dont-have-installed">
          <span>Dont’ have FrameS </span>installed ?
        </h3>
        <div className="spacer-xs-2"></div>
        <div className="row justify-content-center">
          <div className="col-sm-11">
            <div className="d-grid gap-3">
              <span className="btn btn-primary" onClick={() => downloadApp('windows')}>
                Download for Windows
              </span>
              <span className="btn btn-primary" onClick={() => downloadApp('mac')}>
                Download for Mac
              </span>
            </div>
          </div>
        </div>
        <div className="spacer-xs-2"></div>
        <div className="row justify-content-center">
          <div className="col-sm-11 ">
            <div className="entry-info1 getting-started-info">
              <h4 className="text-black">Discover steps </h4>
              <ul className="list-unstyled p-0">
                <li className="fw-normal">Step 1: Download FrameS </li>
                <li className="fw-normal">Step 2: Install FrameS</li>
                <li className="fw-normal">Step 3: Click on Launch link</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  ) : appReadyError ? (
    <p>{appReadyErrorMsg}</p>
  ) : (
    <Loader />
  );
}
